/*!
 * Copyright Vilgem
 */

/* ***************** */

.overflow-h {
    overflow: hidden;
}

.ow-bw {
    overflow-wrap: break-word;
}

.border-n {
    border: none;
}

.out-n {
    outline: none;
}

.br-50 {
    border-radius: 50%;
}

.br-0 {
    border-radius: 0;
}

.br-0i {
    border-radius: 0 !important;
}

/* color css*/
.white {
    color: white;
}

.yellow {
    color: yellow;
}

.grey {
    color: grey;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.green {
    color: green;
}

/* display css */
/* legacy values */
.dis-b {
    display: block;
}

.dis-i {
    display: inline;
}

.dis-ib {
    display: inline-block;
}

.dis-g {
    display: grid;
}

.dis-f {
    display: flex;
}

.dis-if {
    display: inline-flex;
}

.dis-ig {
    display: inline-grid;
}

.dis-fr {
    display: flow-root;
}

/* box generation */
.dis-n {
    display: none;
}

.dis-c {
    display: contents;
}

.fd-r {
    flex-direction: row;
}

.fd-rr {
    flex-direction: row-reverse;
}

.fd-c {
    flex-direction: column;
}

.fd-cr {
    flex-direction: column-reverse;
}

/* just set css */
.justify {
    display: flex;
    justify-content: center;
    align-items: center;
}

.justify-s {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.jc-sb {
    justify-content: space-between;
}

.jc-se {
    justify-content: space-evenly;
}

.jc-sa {
    justify-content: space-around;
}

.jc-fs {
    justify-content: flex-start;
}

.jc-fe {
    justify-content: flex-end;
}

.jc-e {
    justify-content: end;
}

.jc-s {
    justify-content: start;
}

.jc-c {
    justify-content: center;
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3;
}

.flex4 {
    flex: 4;
}

.flex5 {
    flex: 5;
}

.flex6 {
    flex: 6;
}

.flex7 {
    flex: 7;
}

.flex8 {
    flex: 8;
}

.flex9 {
    flex: 9;
}

.flex10 {
    flex: 10;
}



/******************************************** responsive padding  ********************************************/
.res-p2 {
    padding: 20px;
}

@media(max-width:768px) {
    .res-p2 {
        padding: 10px;
    }
}

@media(max-width:576px) {
    .res-p2 {
        padding: 5px;
    }
}

.res-p3 {
    padding: 30px;
}

@media(max-width:768px) {
    .res-p3 {
        padding: 20px;
    }
}

@media(max-width:576px) {
    .res-p3 {
        padding: 10px;
    }
}

.res-p4 {
    padding: 40px;
}

@media(max-width:768px) {
    .res-p4 {
        padding: 25px;
    }
}

@media(max-width:576px) {
    .res-p4 {
        padding: 10px;
    }
}

.res-p5 {
    padding: 50px;
}

@media(max-width:768px) {
    .res-p5 {
        padding: 35px;
    }
}

@media(max-width:576px) {
    .res-p5 {
        padding: 15px;
    }
}

.res-p6 {
    padding: 60px;
}

@media(max-width:768px) {
    .res-p6 {
        padding: 40px;
    }
}

@media(max-width:576px) {
    .res-p6 {
        padding: 20px;
    }
}

.res-p7 {
    padding: 70px;
}

@media(max-width:768px) {
    .res-p7 {
        padding: 50px;
    }
}

@media(max-width:576px) {
    .res-p7 {
        padding: 30px;
    }
}



/* responsive padding right */
.res-pr2 {
    padding-right: 20px;
}

@media(max-width:768px) {
    .res-pr2 {
        padding-right: 10px;
    }
}

@media(max-width:576px) {
    .res-pr2 {
        padding-right: 5px;
    }
}

.res-pr3 {
    padding-right: 30px;
}

@media(max-width:768px) {
    .res-pr3 {
        padding-right: 20px;
    }
}

@media(max-width:576px) {
    .res-pr3 {
        padding-right: 10px;
    }
}

.res-pr4 {
    padding-right: 40px;
}

@media(max-width:768px) {
    .res-pr4 {
        padding-right: 25px;
    }
}

@media(max-width:576px) {
    .res-pr4 {
        padding-right: 10px;
    }
}

.res-pr5 {
    padding-right: 50px;
}

@media(max-width:768px) {
    .res-pr5 {
        padding-right: 35px;
    }
}

@media(max-width:576px) {
    .res-pr5 {
        padding-right: 15px;
    }
}

.res-pr6 {
    padding-right: 60px;
}

@media(max-width:768px) {
    .res-pr6 {
        padding-right: 40px;
    }
}

@media(max-width:576px) {
    .res-pr6 {
        padding-right: 20px;
    }
}

.res-pr7 {
    padding-right: 70px;
}

@media(max-width:768px) {
    .res-pr7 {
        padding-right: 50px;
    }
}

@media(max-width:576px) {
    .res-pr7 {
        padding-right: 30px;
    }
}


/* responsive padding left */

.res-pl2 {
    padding-left: 20px;
}

@media(max-width:768px) {
    .res-pl2 {
        padding-left: 10px;
    }
}

@media(max-width:576px) {
    .res-pl2 {
        padding-left: 5px;
    }
}

.res-pl3 {
    padding-left: 30px;
}

@media(max-width:768px) {
    .res-pl3 {
        padding-left: 20px;
    }
}

@media(max-width:576px) {
    .res-pl3 {
        padding-left: 10px;
    }
}

.res-pl4 {
    padding-left: 40px;
}

@media(max-width:768px) {
    .res-pl4 {
        padding-left: 25px;
    }
}

@media(max-width:576px) {
    .res-pl4 {
        padding-left: 10px;
    }
}

.res-pl5 {
    padding-left: 50px;
}

@media(max-width:768px) {
    .res-pl5 {
        padding-left: 35px;
    }
}

@media(max-width:576px) {
    .res-pl5 {
        padding-left: 15px;
    }
}

.res-pl6 {
    padding-left: 60px;
}

@media(max-width:768px) {
    .res-pl6 {
        padding-left: 40px;
    }
}

@media(max-width:576px) {
    .res-pl6 {
        padding-left: 20px;
    }
}

.res-pl7 {
    padding-left: 70px;
}

@media(max-width:768px) {
    .res-pl7 {
        padding-left: 50px;
    }
}

@media(max-width:576px) {
    .res-pl7 {
        padding-left: 30px;
    }
}


/* responsive padding top */

.res-pt2 {
    padding-top: 20px;
}

@media(max-width:768px) {
    .res-pt2 {
        padding-top: 10px;
    }
}

@media(max-width:576px) {
    .res-pt2 {
        padding-top: 5px;
    }
}

.res-pt3 {
    padding-top: 30px;
}

@media(max-width:768px) {
    .res-pt3 {
        padding-top: 20px;
    }
}

@media(max-width:576px) {
    .res-pt3 {
        padding-top: 10px;
    }
}

.res-pt4 {
    padding-top: 40px;
}

@media(max-width:768px) {
    .res-pt4 {
        padding-top: 25px;
    }
}

@media(max-width:576px) {
    .res-pt4 {
        padding-top: 10px;
    }
}

.res-pt5 {
    padding-top: 50px;
}

@media(max-width:768px) {
    .res-pt5 {
        padding-top: 35px;
    }
}

@media(max-width:576px) {
    .res-pt5 {
        padding-top: 15px;
    }
}

.res-pt6 {
    padding-top: 60px;
}

@media(max-width:768px) {
    .res-pt6 {
        padding-top: 40px;
    }
}

@media(max-width:576px) {
    .res-pt6 {
        padding-top: 20px;
    }
}

.res-pt7 {
    padding-top: 70px;
}

@media(max-width:768px) {
    .res-pt7 {
        padding-top: 50px;
    }
}

@media(max-width:576px) {
    .res-pt7 {
        padding-top: 30px;
    }
}



/* responsive padding bottom */

.res-pb2 {
    padding-bottom: 20px;
}

@media(max-width:768px) {
    .res-pb2 {
        padding-bottom: 10px;
    }
}

@media(max-width:576px) {
    .res-pb2 {
        padding-bottom: 5px;
    }
}

.res-pb3 {
    padding-bottom: 30px;
}

@media(max-width:768px) {
    .res-pb3 {
        padding-bottom: 20px;
    }
}

@media(max-width:576px) {
    .res-pb3 {
        padding-bottom: 10px;
    }
}

.res-pb4 {
    padding-bottom: 40px;
}

@media(max-width:768px) {
    .res-pb4 {
        padding-bottom: 25px;
    }
}

@media(max-width:576px) {
    .res-pb4 {
        padding-bottom: 10px;
    }
}

.res-pb5 {
    padding-bottom: 50px;
}

@media(max-width:768px) {
    .res-pb5 {
        padding-bottom: 35px;
    }
}

@media(max-width:576px) {
    .res-pb5 {
        padding-bottom: 15px;
    }
}

.res-pb6 {
    padding-bottom: 60px;
}

@media(max-width:768px) {
    .res-pb6 {
        padding-bottom: 40px;
    }
}

@media(max-width:576px) {
    .res-pb6 {
        padding-bottom: 20px;
    }
}

.res-pb7 {
    padding-bottom: 70px;
}

@media(max-width:768px) {
    .res-pb7 {
        padding-bottom: 50px;
    }
}

@media(max-width:576px) {
    .res-pb7 {
        padding-bottom: 30px;
    }
}




/******************************************** responsive margin  ********************************************/
.res-m2 {
    margin: 20px;
}

@media(max-width:768px) {
    .res-m2 {
        margin: 10px;
    }
}

@media(max-width:576px) {
    .res-m2 {
        margin: 5px;
    }
}

.res-m3 {
    margin: 30px;
}

@media(max-width:768px) {
    .res-m3 {
        margin: 20px;
    }
}

@media(max-width:576px) {
    .res-m3 {
        margin: 10px;
    }
}

.res-m4 {
    margin: 40px;
}

@media(max-width:768px) {
    .res-m4 {
        margin: 25px;
    }
}

@media(max-width:576px) {
    .res-m4 {
        margin: 10px;
    }
}

.res-m5 {
    margin: 50px;
}

@media(max-width:768px) {
    .res-m5 {
        margin: 35px;
    }
}

@media(max-width:576px) {
    .res-m5 {
        margin: 15px;
    }
}

.res-m6 {
    margin: 60px;
}

@media(max-width:768px) {
    .res-m6 {
        margin: 40px;
    }
}

@media(max-width:576px) {
    .res-m6 {
        margin: 20px;
    }
}

.res-m7 {
    margin: 70px;
}

@media(max-width:768px) {
    .res-m7 {
        margin: 50px;
    }
}

@media(max-width:576px) {
    .res-m7 {
        margin: 30px;
    }
}



/* responsive margin right */
.res-mr2 {
    margin-right: 20px;
}

@media(max-width:768px) {
    .res-mr2 {
        margin-right: 10px;
    }
}

@media(max-width:576px) {
    .res-mr2 {
        margin-right: 5px;
    }
}

.res-mr3 {
    margin-right: 30px;
}

@media(max-width:768px) {
    .res-mr3 {
        margin-right: 20px;
    }
}

@media(max-width:576px) {
    .res-mr3 {
        margin-right: 10px;
    }
}

.res-mr4 {
    margin-right: 40px;
}

@media(max-width:768px) {
    .res-mr4 {
        margin-right: 25px;
    }
}

@media(max-width:576px) {
    .res-mr4 {
        margin-right: 10px;
    }
}

.res-mr5 {
    margin-right: 50px;
}

@media(max-width:768px) {
    .res-mr5 {
        margin-right: 35px;
    }
}

@media(max-width:576px) {
    .res-mr5 {
        margin-right: 15px;
    }
}

.res-mr6 {
    margin-right: 60px;
}

@media(max-width:768px) {
    .res-mr6 {
        margin-right: 40px;
    }
}

@media(max-width:576px) {
    .res-mr6 {
        margin-right: 20px;
    }
}

.res-mr7 {
    margin-right: 70px;
}

@media(max-width:768px) {
    .res-mr7 {
        margin-right: 50px;
    }
}

@media(max-width:576px) {
    .res-mr7 {
        margin-right: 30px;
    }
}


/* responsive margin left */

.res-ml2 {
    margin-left: 20px;
}

@media(max-width:768px) {
    .res-ml2 {
        margin-left: 10px;
    }
}

@media(max-width:576px) {
    .res-ml2 {
        margin-left: 5px;
    }
}

.res-ml3 {
    margin-left: 30px;
}

@media(max-width:768px) {
    .res-ml3 {
        margin-left: 20px;
    }
}

@media(max-width:576px) {
    .res-ml3 {
        margin-left: 10px;
    }
}

.res-ml4 {
    margin-left: 40px;
}

@media(max-width:768px) {
    .res-ml4 {
        margin-left: 25px;
    }
}

@media(max-width:576px) {
    .res-ml4 {
        margin-left: 10px;
    }
}

.res-ml5 {
    margin-left: 50px;
}

@media(max-width:768px) {
    .res-ml5 {
        margin-left: 35px;
    }
}

@media(max-width:576px) {
    .res-ml5 {
        margin-left: 15px;
    }
}

.res-ml6 {
    margin-left: 60px;
}

@media(max-width:768px) {
    .res-ml6 {
        margin-left: 40px;
    }
}

@media(max-width:576px) {
    .res-ml6 {
        margin-left: 20px;
    }
}

.res-ml7 {
    margin-left: 70px;
}

@media(max-width:768px) {
    .res-ml7 {
        margin-left: 50px;
    }
}

@media(max-width:576px) {
    .res-ml7 {
        margin-left: 30px;
    }
}


/* responsive margin top */

.res-mt2 {
    margin-top: 20px;
}

@media(max-width:768px) {
    .res-mt2 {
        margin-top: 10px;
    }
}

@media(max-width:576px) {
    .res-mt2 {
        margin-top: 5px;
    }
}

.res-mt3 {
    margin-top: 30px;
}

@media(max-width:768px) {
    .res-mt3 {
        margin-top: 20px;
    }
}

@media(max-width:576px) {
    .res-mt3 {
        margin-top: 10px;
    }
}

.res-mt4 {
    margin-top: 40px;
}

@media(max-width:768px) {
    .res-mt4 {
        margin-top: 25px;
    }
}

@media(max-width:576px) {
    .res-mt4 {
        margin-top: 10px;
    }
}

.res-mt5 {
    margin-top: 50px;
}

@media(max-width:768px) {
    .res-mt5 {
        margin-top: 35px;
    }
}

@media(max-width:576px) {
    .res-mt5 {
        margin-top: 15px;
    }
}

.res-mt6 {
    margin-top: 60px;
}

@media(max-width:768px) {
    .res-mt6 {
        margin-top: 40px;
    }
}

@media(max-width:576px) {
    .res-mt6 {
        margin-top: 20px;
    }
}

.res-mt7 {
    margin-top: 70px;
}

@media(max-width:768px) {
    .res-mt7 {
        margin-top: 50px;
    }
}

@media(max-width:576px) {
    .res-mt7 {
        margin-top: 30px;
    }
}



/* responsive margin bottom */

.res-mb2 {
    margin-bottom: 20px;
}

@media(max-width:768px) {
    .res-mb2 {
        margin-bottom: 10px;
    }
}

@media(max-width:576px) {
    .res-mb2 {
        margin-bottom: 5px;
    }
}

.res-mb3 {
    margin-bottom: 30px;
}

@media(max-width:768px) {
    .res-mb3 {
        margin-bottom: 20px;
    }
}

@media(max-width:576px) {
    .res-mb3 {
        margin-bottom: 10px;
    }
}

.res-mb4 {
    margin-bottom: 40px;
}

@media(max-width:768px) {
    .res-mb4 {
        margin-bottom: 25px;
    }
}

@media(max-width:576px) {
    .res-mb4 {
        margin-bottom: 10px;
    }
}

.res-mb5 {
    margin-bottom: 50px;
}

@media(max-width:768px) {
    .res-mb5 {
        margin-bottom: 35px;
    }
}

@media(max-width:576px) {
    .res-mb5 {
        margin-bottom: 15px;
    }
}

.res-mb6 {
    margin-bottom: 60px;
}

@media(max-width:768px) {
    .res-mb6 {
        margin-bottom: 40px;
    }
}

@media(max-width:576px) {
    .res-mb6 {
        margin-bottom: 20px;
    }
}

.res-mb7 {
    margin-bottom: 70px;
}

@media(max-width:768px) {
    .res-mb7 {
        margin-bottom: 50px;
    }
}

@media(max-width:576px) {
    .res-mb7 {
        margin-bottom: 30px;
    }
}