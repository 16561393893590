.dash-sidebar {
    background: rgb(248, 245, 245);
}

.dark .dash-sidebar {
    background: #232132 !important;
}

.active-color {
    color: rgba(255, 202, 87, 1) !important;
}

.sidebar-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    width: 100%;
    background: #393746;
    border-radius: 15px;

    .circle {
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 50px;
        height: 50px;
    }

    .yellow-circle {
        background: linear-gradient(180deg, #FFCA57 0%, #FFA900 100%);
        padding: 0 8px;
        border-radius: 50%;
    }

    .sidebar-footer-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 24px;
        gap: 10px;
        width: 100%;
        background: linear-gradient(180deg, #FFCA57 0%, #FFA900 100%);
        border-radius: 10px;
    }
}