.nft-section {
    padding-top: 30px;

    .nft-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        gap: 8px;


        background: rgba(255, 255, 255, 0.1);
        border-radius: 55px;

        .plus-icon {
            padding: 5px;
            border-radius: 50%;
            background: #09CE11;
        }
    }

    .nft-search {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        gap: 8px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
    }

    .nft-group {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        transition: all .35s ease 0s;
        gap: 0.75rem;

        .nft-list {
            border: 2px grey solid;
            border-radius: 10px;
            background: transparent;
            padding: 5px;

            img {
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }

            .title {
                font-size: 18px;
                font-weight: bold;
                margin-top: 25px;
                width: 100%;
                text-align: center;
            }
        }
    }

}