.isloading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2147483001;
    display: flex;
    background-color: rgb(24, 26, 25);
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity .4s ease-in-out, z-index .4s step-end;
}

.dis-none {
    display: none !important;
}

.loading-img {
    animation-name: loading;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes loading {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

.font75 {
    color: rgba(255, 255, 255, 0.75);
}

.show-loading {
    display: flex !important;
}

.delay-time {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999;
    background: #0F0F0F;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    .react-loading-style {
        margin: auto;
    }
}

.first-section {
    background: #0F0F0F;
    background-repeat: no-repeat;
    background-size: 175% 250%;
    // background-size: cover;
    background-position: center;
    padding-bottom: 80px;
    background-image: url("../../assets/background_.png");
    // background-image: url("/public/assets/logo.svg");

    .header-part {
        background: black;
        opacity: 0.7;
        padding: 25px;
    }

    .home-img {
        width: 100%;
        height: 100%;
    }


    .feature-card {
        .icon-section {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 60px;
            height: 60px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
        }

        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: #232132;
        padding: 20px;
        align-items: flex-start;
        border-radius: 20px;
    }

    @media (max-width:600px) {
        .watch-video {
            // margin-top: 20px;
            // margin-left: 0%;
        }
    }


}

@media (max-width:600px) {
    .first-section {
        background-size: 400% 140%;
    }
}

.simple-section {
    background: black;
    padding: 0px 30px;
    width: 100%;
    position: relative;


}

.up-icon {
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    padding: 3px;
    width: auto;
    z-index: 2;
    background: #EFB219;
    border-radius: 50%;
}

.dead-wallet-section {
    background: #0F0F0F;
    padding: 0px 30px;
    width: 100%;
}



.spend-section {
    background: #000000;

    .flash-recharge-card2 {
        position: absolute;
        top: 40px;
        left: 60px;
    }

    @media (max-width:600px) {}

    @media (max-width:768px) {}
}

.multi-chain-section {
    background-color: #0F0F0F;

    .multi-chain-card-container {
        // w-full flex flex-wrap justify-center text-center gap-6 mt-10 m-w-[600px
        width: 100%;
        max-width: 770px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 1.5rem;
        margin: auto;

        .multi-chain-card {
            background-color: #1B1D1C;
            border-radius: 16px;
            height: 430px;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            max-width: 350px;
            width: 100%;
            padding: 20px;

            span {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.7);
            }

            .multi-chain-card-top {
                position: absolute;
                top: 0;
                left: 40px;
                right: 40px;
                border: 0.5px solid #EFB219;
            }

            .multi-chain-card-bottom {
                position: absolute;
                bottom: 0;
                left: 40px;
                right: 40px;
                border: 1px solid #EFB219;
                opacity: 0.3;
            }
        }
    }

}

.partners-section {
    background: black;
    padding-bottom: 70px;

    .partners-img {
        position: absolute;
        top: -200px;
        max-width: 600px;
        width: 100%;


    }

    @media (max-width:1400px) {
        .partners-img {
            max-width: 550px;
            position: absolute;
            width: 100%;
            top: -110px;
        }
    }

    @media (max-width:1200px) {
        .partners-img {
            max-width: 550px;
            position: relative;
            width: 100%;
            top: 0;
        }
    }

}

.mobile-section {
    background: #0F0F0F;
}

.follow-section {
    background: black;
}

.trustpilot-section {
    background: #0F0F0F;
    position: relative;


    .pattern {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
    }

    .partners-card-container {
        // w-full flex flex-wrap justify-center text-center gap-6 mt-10 m-w-[600px
        width: 100%;
        max-width: 1350px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 2rem;
        margin: auto;

        .partners-card {
            background-color: #1B1D1C;
            border-radius: 16px;
            height: 100px;
            width: 170px;
            margin-top: 10px;
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 20px;

            span {
                font-size: 13px;
                color: rgba(255, 255, 255, 0.7);
            }

            .partners-card-top {
                position: absolute;
                top: 0;
                left: 40px;
                right: 40px;
                border: 0.5px solid #EFB219;
            }

            .partners-card-bottom {
                position: absolute;
                bottom: 0;
                left: 40px;
                right: 40px;
                border: 1px solid #EFB219;
                opacity: 0.3;
            }
        }
    }


    @media (max-width:400px) {
        .partners-card-container {
            width: 100%;
            max-width: 1350px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            gap: 1rem;
            margin: auto;

            .partners-card {
                background-color: #1B1D1C;
                border-radius: 16px;
                height: 100px;
                width: 155px;
            }
        }
    }
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #464545 #0F0F0F;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: #0F0F0F;
}

*::-webkit-scrollbar-thumb {
    background-color: #464545;
    border-radius: 10px;
}

.small-underline {
    border: 1px #EFB219 solid;
    width: 30px;
}

.both-border {
    border-right: 2px grey solid;
    border-left: 2px grey solid;
}

.right-border {
    border-right: 2px grey solid;
}

.right-border {
    border-right: 2px grey solid;
}

@media (max-width:600px) {
    .both-border {
        border-right: 0px;
        border-left: 0px;
    }

    .right-border {
        border-right: 0px;
        border-top: 2px grey solid;
        margin-top: 20px;
    }

    .right-border {
        border-right: 0px;
        border-top: 2px grey solid;
        margin-top: 20px;
    }
}

.request-setting-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(0px);
    animation: 0.3s ease-in-out 0s 1 normal forwards running opacity-animation;
    overflow-y: auto;
    padding-top: 3%;
    padding-bottom: 3%;
    z-index: 99999999999;
}

.request-setting-modal-bg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}

.request-setting-modal-body {
    max-width: 700px;
    margin: auto;
    width: 100%;
    background: #141414;
    z-index: 9999;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}

.request-modal-header {
    background: #191727;
}

.modal-content {
    overflow: auto;
    height: 100%;
    max-height: 700px;
}


// ------------ status modal --------------

.status-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(0px);
    animation: 0.3s ease-in-out 0s 1 normal forwards running opacity-animation;
    overflow-y: auto;
    padding-top: 3%;
    padding-bottom: 3%;
    z-index: 99999999999;

    .modal-content {
        overflow: auto;
        height: 100%;
        max-height: 700px;
    }

    .status-modal-bg {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9998;
        color: black;
    }

    .status-modal-body {
        max-width: 800px;
        margin: auto;
        width: 100%;
        background: white;
        z-index: 9999;
        border-radius: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
    }

    .status-modal-header {
        position: relative;

        .status-close {
            position: absolute;
            right: 30px;
            top: 30px;
        }

        // background: #191727;
    }
}

@media (max-width:600px) {
    .mo-dis-none {
        display: none;
    }

}

@media (max-width:768px) {
    .table-dis-none {
        display: none;
    }
}

@media (max-width:998px) {
    .pc-dis-none {
        display: none;
    }
}