.group:hover .group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 115, 142, var(--tw-border-opacity));
}

.focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(160, 173, 194, var(--tw-border-opacity));
}

.hover\:border-primary:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 193, 19, var(--tw-border-opacity));
}

.hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(160, 173, 194, var(--tw-border-opacity));
}

.focus\:border-primary:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 193, 19, var(--tw-border-opacity));
}

.focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(204, 215, 233, var(--tw-border-opacity));
}

.focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(160, 173, 194, var(--tw-border-opacity));
}

.dark .dark\:border-primary {
    --tw-border-opacity: 1;
    border-color: rgba(253, 193, 19, var(--tw-border-opacity));
}

.dark .dark\:border-secondary {
    --tw-border-opacity: 1;
    border-color: rgba(27, 200, 112, var(--tw-border-opacity));
}

.dark .dark\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(97, 115, 142, var(--tw-border-opacity));
}

.dark .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 92, 117, var(--tw-border-opacity));
}

.dark .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 61, 80, var(--tw-border-opacity));
}

.dark .dark\:border-error {
    --tw-border-opacity: 1;
    border-color: rgba(194, 22, 22, var(--tw-border-opacity));
}

.dark .dark\:focus-within\:border-secondary:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(27, 200, 112, var(--tw-border-opacity));
}

.dark .dark\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(97, 115, 142, var(--tw-border-opacity));
}

.dark .dark\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(97, 115, 142, var(--tw-border-opacity));
}

.dark .dark\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 92, 117, var(--tw-border-opacity));
}

.border-opacity-40 {
    --tw-border-opacity: 0.4;
}

.focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
}

.dark .dark\:border-opacity-40 {
    --tw-border-opacity: 0.4;
}

.bg-transparent {
    background-color: transparent;
}

.bg-white {
    background-color: #fff;
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 193, 19, var(--tw-bg-opacity));
}

.bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 200, 112, var(--tw-bg-opacity));
}

.bg-secondary-dark {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 101, 39, var(--tw-bg-opacity));
}

.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 251, var(--tw-bg-opacity));
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 238, 246, var(--tw-bg-opacity));
}

.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 215, 233, var(--tw-bg-opacity));
}

.bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(160, 173, 194, var(--tw-bg-opacity));
}

.bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 92, 117, var(--tw-bg-opacity));
}

.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 61, 80, var(--tw-bg-opacity));
}

.bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 53, var(--tw-bg-opacity));
}

.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 23, 34, var(--tw-bg-opacity));
}

.bg-success-bright {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 200, 112, var(--tw-bg-opacity));
}

.bg-alert-light {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 242, 209, var(--tw-bg-opacity));
}

.bg-alert-bright {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 222, 106, var(--tw-bg-opacity));
}

.bg-error-light {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 217, 217, var(--tw-bg-opacity));
}

.bg-error-bright {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 106, 106, var(--tw-bg-opacity));
}

.bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 22, 22, var(--tw-bg-opacity));
}

.bg-error-dark {
    --tw-bg-opacity: 1;
    background-color: rgba(150, 10, 10, var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 193, 19, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 251, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 238, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(204, 215, 233, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(160, 173, 194, var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 61, 80, var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 53, var(--tw-bg-opacity));
}

.hover\:bg-error-light:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 217, 217, var(--tw-bg-opacity));
}

.hover\:bg-error-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(150, 10, 10, var(--tw-bg-opacity));
}

.dark .dark\:bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 193, 19, var(--tw-bg-opacity));
}

.dark .dark\:bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 200, 112, var(--tw-bg-opacity));
}

.dark .dark\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(97, 115, 142, var(--tw-bg-opacity));
}

.dark .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 61, 80, var(--tw-bg-opacity));
}

.dark .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 53, var(--tw-bg-opacity));
}

.dark .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 23, 34, var(--tw-bg-opacity));
}

.dark .dark\:bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(194, 22, 22, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 193, 19, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-primary-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(207, 154, 0, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(160, 173, 194, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 61, 80, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 39, 53, var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 23, 34, var(--tw-bg-opacity));
}

.bg-opacity-5 {
    --tw-bg-opacity: 0.05;
}

.bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}

.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}

.bg-opacity-30 {
    --tw-bg-opacity: 0.3;
}

.bg-opacity-40 {
    --tw-bg-opacity: 0.4;
}

.bg-opacity-50 {
    --tw-bg-opacity: 0.5;
}

.bg-opacity-60 {
    --tw-bg-opacity: 0.6;
}

.bg-opacity-75 {
    --tw-bg-opacity: 0.75;
}

.bg-opacity-90 {
    --tw-bg-opacity: 0.9;
}

.hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
}

.hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
}

.hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
}

.hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
}

.hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
}

.dark .dark\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}

.dark .dark\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}

.dark .dark\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
}

.dark .dark\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
}

.dark .dark\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
}

.dark .dark\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
}

.dark .dark\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
}

.dark .dark\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
}

.dark .dark\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
}

.dark .dark\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
}

.bg-gradient-to-t {
    background-image: linear-gradient(0deg, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
}

.from-primary {
    --tw-gradient-from: #fdc113;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 193, 19, 0));
}

.from-primary-dark {
    --tw-gradient-from: #cf9a00;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(207, 154, 0, 0));
}

.from-secondary {
    --tw-gradient-from: #1bc870;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 200, 112, 0));
}

.dark .dark\:from-gray-700,
.from-gray-700 {
    --tw-gradient-from: #313d50;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 61, 80, 0));
}

.to-primary {
    --tw-gradient-to: #fdc113;
}

.to-primary-dark {
    --tw-gradient-to: #cf9a00;
}

.to-secondary-dark {
    --tw-gradient-to: #106527;
}

.dark .dark\:to-gray-600,
.to-gray-600 {
    --tw-gradient-to: #4c5c75;
}

.dark .dark\:to-gray-800 {
    --tw-gradient-to: #1e2735;
}

.fill-current {
    fill: currentColor;
}

.text-blue-light {
    --tw-text-opacity: 1;
    color: rgba(149, 193, 255, var(--tw-text-opacity));
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgba(253, 193, 19, var(--tw-text-opacity));
}

.text-primary-dark {
    --tw-text-opacity: 1;
    color: rgba(207, 154, 0, var(--tw-text-opacity));
}

.text-primary-xtra_dark {
    --tw-text-opacity: 1;
    color: rgba(101, 76, 2, var(--tw-text-opacity));
}

.text-secondary {
    --tw-text-opacity: 1;
    color: rgba(27, 200, 112, var(--tw-text-opacity));
}

.text-secondary-dark {
    --tw-text-opacity: 1;
    color: rgba(16, 101, 39, var(--tw-text-opacity));
}

.text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(232, 238, 246, var(--tw-text-opacity));
}

.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(204, 215, 233, var(--tw-text-opacity));
}

.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(160, 173, 194, var(--tw-text-opacity));
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(127, 143, 169, var(--tw-text-opacity));
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-text-opacity));
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(76, 92, 117, var(--tw-text-opacity));
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(49, 61, 80, var(--tw-text-opacity));
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 53, var(--tw-text-opacity));
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(16, 23, 34, var(--tw-text-opacity));
}

.text-success-bright {
    --tw-text-opacity: 1;
    color: rgba(27, 200, 112, var(--tw-text-opacity));
}

.text-success-dark {
    --tw-text-opacity: 1;
    color: rgba(11, 83, 31, var(--tw-text-opacity));
}

.text-alert-bright {
    --tw-text-opacity: 1;
    color: rgba(255, 222, 106, var(--tw-text-opacity));
}

.text-alert {
    --tw-text-opacity: 1;
    color: rgba(234, 134, 18, var(--tw-text-opacity));
}

.text-alert-dark {
    --tw-text-opacity: 1;
    color: rgba(198, 110, 0, var(--tw-text-opacity));
}

.text-error-bright {
    --tw-text-opacity: 1;
    color: rgba(255, 106, 106, var(--tw-text-opacity));
}

.text-error {
    --tw-text-opacity: 1;
    color: rgba(194, 22, 22, var(--tw-text-opacity));
}

.text-error-dark {
    --tw-text-opacity: 1;
    color: rgba(150, 10, 10, var(--tw-text-opacity));
}

.hover\:text-white:hover {
    color: #fff;
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 193, 19, var(--tw-text-opacity));
}

.hover\:text-primary-dark:hover {
    --tw-text-opacity: 1;
    color: rgba(207, 154, 0, var(--tw-text-opacity));
}

.hover\:text-primary-xtra_dark:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 76, 2, var(--tw-text-opacity));
}

.hover\:text-secondary-dark:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 101, 39, var(--tw-text-opacity));
}

.hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 238, 246, var(--tw-text-opacity));
}

.hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(160, 173, 194, var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 53, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 23, 34, var(--tw-text-opacity));
}

.hover\:text-error-dark:hover {
    --tw-text-opacity: 1;
    color: rgba(150, 10, 10, var(--tw-text-opacity));
}

.dark .dark\:text-white {
    color: #fff;
}

.dark .dark\:text-primary-light {
    --tw-text-opacity: 1;
    color: rgba(255, 231, 159, var(--tw-text-opacity));
}

.dark .dark\:text-primary {
    --tw-text-opacity: 1;
    color: rgba(253, 193, 19, var(--tw-text-opacity));
}

.dark .dark\:text-primary-xtra_dark {
    --tw-text-opacity: 1;
    color: rgba(101, 76, 2, var(--tw-text-opacity));
}

.dark .dark\:text-secondary {
    --tw-text-opacity: 1;
    color: rgba(27, 200, 112, var(--tw-text-opacity));
}

.dark .dark\:text-secondary-dark {
    --tw-text-opacity: 1;
    color: rgba(16, 101, 39, var(--tw-text-opacity));
}

.dark .dark\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(232, 238, 246, var(--tw-text-opacity));
}

.dark .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(204, 215, 233, var(--tw-text-opacity));
}

.dark .dark\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(160, 173, 194, var(--tw-text-opacity));
}

.dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(127, 143, 169, var(--tw-text-opacity));
}

.dark .dark\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-text-opacity));
}

.dark .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(76, 92, 117, var(--tw-text-opacity));
}

.dark .dark\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 39, 53, var(--tw-text-opacity));
}

.dark .dark\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(16, 23, 34, var(--tw-text-opacity));
}

.dark .dark\:text-success-bright {
    --tw-text-opacity: 1;
    color: rgba(27, 200, 112, var(--tw-text-opacity));
}

.dark .dark\:text-alert-bright {
    --tw-text-opacity: 1;
    color: rgba(255, 222, 106, var(--tw-text-opacity));
}

.dark .dark\:text-error-bright {
    --tw-text-opacity: 1;
    color: rgba(255, 106, 106, var(--tw-text-opacity));
}

.dark .dark\:text-error {
    --tw-text-opacity: 1;
    color: rgba(194, 22, 22, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-white:hover {
    color: #fff;
}

.dark .dark\:hover\:text-primary-light:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 231, 159, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 193, 19, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-primary-xtra_dark:hover {
    --tw-text-opacity: 1;
    color: rgba(101, 76, 2, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-secondary-dark:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 101, 39, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(232, 238, 246, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 215, 233, var(--tw-text-opacity));
}

.text-opacity-50 {
    --tw-text-opacity: 0.5;
}

.hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
}

.hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
}

.hover\:underline:hover,
.underline {
    text-decoration: underline;
}

.placeholder-gray-500::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-placeholder-opacity));
}

.placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-placeholder-opacity));
}

.placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-placeholder-opacity));
}

.placeholder-gray-700::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 61, 80, var(--tw-placeholder-opacity));
}

.placeholder-gray-700:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 61, 80, var(--tw-placeholder-opacity));
}

.placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 61, 80, var(--tw-placeholder-opacity));
}

.dark .dark\:placeholder-gray-200::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 215, 233, var(--tw-placeholder-opacity));
}

.dark .dark\:placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 215, 233, var(--tw-placeholder-opacity));
}

.dark .dark\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(204, 215, 233, var(--tw-placeholder-opacity));
}

.dark .dark\:placeholder-gray-500::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-placeholder-opacity));
}

.dark .dark\:placeholder-gray-500:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-placeholder-opacity));
}

.dark .dark\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(97, 115, 142, var(--tw-placeholder-opacity));
}

.placeholder-opacity-50::-moz-placeholder {
    --tw-placeholder-opacity: 0.5;
}

.placeholder-opacity-50:-ms-input-placeholder {
    --tw-placeholder-opacity: 0.5;
}

.placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
}

.opacity-0 {
    opacity: 0;
}

.opacity-50 {
    opacity: .5;
}

.opacity-75 {
    opacity: .75;
}

.opacity-90 {
    opacity: .9;
}

.opacity-100 {
    opacity: 1;
}

.hover\:opacity-70:hover {
    opacity: .7;
}

.hover\:opacity-80:hover {
    opacity: .8;
}

.hover\:opacity-100:hover {
    opacity: 1;
}

*,
:after,
:before {
    --tw-shadow: 0 0 transparent;
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow,
.shadow-sm {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg,
.shadow-md {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-glow,
.shadow-inner {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.shadow-glow {
    --tw-shadow: 0px 0px 20px rgba(253, 193, 19, 0.28);
}

.shadow-secondary {
    --tw-shadow: 0px 0px 20px rgba(41, 255, 87, 0.24);
}

.shadow-secondary,
.shadow-smooth {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.shadow-smooth {
    --tw-shadow: 0 5px 10px rgba(187, 202, 220, 0.37);
}

.shadow-strong {
    --tw-shadow: 0 5px 10px rgba(0, 0, 0, 0.44);
}

.hover\:shadow-glow:hover,
.shadow-strong {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.hover\:shadow-glow:hover {
    --tw-shadow: 0px 0px 20px rgba(253, 193, 19, 0.28);
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

*,
:after,
:before {
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.focus\:ring-0:focus,
.focus\:ring-1:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent);
}

.focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(97, 115, 142, var(--tw-ring-opacity));
}

.focus\:ring-primary:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 193, 19, var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(204, 215, 233, var(--tw-ring-opacity));
}

.focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(160, 173, 194, var(--tw-ring-opacity));
}

.ring-opacity-10 {
    --tw-ring-opacity: 0.1;
}

.filter {
    --tw-blur: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-brightness: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-contrast: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-grayscale: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-hue-rotate: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-invert: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-saturate: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-sepia: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-drop-shadow: var(--tw-empty,
            /*!*/
            /*!*/
        );
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
    --tw-blur: blur(8px);
}

.backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-brightness: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-contrast: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-grayscale: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-hue-rotate: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-invert: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-opacity: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-saturate: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-backdrop-sepia: var(--tw-empty,
            /*!*/
            /*!*/
        );
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
}

.duration-75 {
    transition-duration: 75ms;
}

.duration-100 {
    transition-duration: .1s;
}

.duration-150 {
    transition-duration: .15s;
}

.duration-200 {
    transition-duration: .2s;
}

.duration-300 {
    transition-duration: .3s;
}

.duration-500 {
    transition-duration: .5s;
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Jost, sans-serif;
}

h1 {
    font-size: 3rem;
    line-height: 3.875rem;
    letter-spacing: -.90625px;
}

h1,
h2 {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 500;
}

h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -.266667px;
}

h3 {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 2rem;
}

h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}

h4,
h5 {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .147059px;
}

h5 {
    font-size: 1.125rem;
    line-height: 1.375rem;
}

.overline {
    font-family: Jost, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.button,
.caption,
button {
    font-style: normal;
    letter-spacing: .4px;
}

.button,
button {
    font-family: Jost, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.bg-background {
    background-color: #e5eaee;
}

::-webkit-scrollbar {
    width: 6px;
    height: 10px;
}

::-webkit-scrollbar-track {
    border: 3px solid transparent;
    box-shadow: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 24px;
    border: 1px solid transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px #4c5c75;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

.dark .shadow-smooth {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .74);
}

@media (min-width:1920px) {
    .container {
        max-width: 1536px;
    }
}

.page-overlay {
    display: none;
}

@media (min-width:1024px) {
    .dark .page-overlay {
        display: block;
        z-index: 51;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        height: 200vh;
        width: 100vw;
        transform: translateY(90vh);
        mix-blend-mode: color;
        background: radial-gradient(50% 50% at 50% 50%, rgba(44, 13, 95, .2) 0, rgba(49, 3, 80, 0) 100%);
        position: fixed;
    }
}

#active-orders,
#orderbook {
    height: 400px;
}

.tableContainer th {
    font-family: Jost, sans-serif;
    font-size: 12px;
    font-weight: 500;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

[aria-label][data-balloon-pos]:before {
    display: none;
}

@media (min-width:440px) {
    .xs\:mt-3 {
        margin-top: .75rem;
    }

    .xs\:h-7 {
        height: 1.75rem;
    }

    .xs\:w-7 {
        width: 1.75rem;
    }

    .xs\:max-w-full {
        max-width: 100%;
    }

    .xs\:flex-row {
        flex-direction: row;
    }

    .xs\:justify-end {
        justify-content: flex-end;
    }

    .xs\:space-x-2>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem*var(--tw-space-x-reverse));
        margin-left: calc(0.5rem*(1 - var(--tw-space-x-reverse)));
    }

    .xs\:space-x-4>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem*var(--tw-space-x-reverse));
        margin-left: calc(1rem*(1 - var(--tw-space-x-reverse)));
    }

    .xs\:space-y-0>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px*(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px*var(--tw-space-y-reverse));
    }

    .xs\:p-2 {
        padding: .5rem;
    }

    .xs\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }

    .xs\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .xs\:py-1 {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .xs\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .xs\:py-4 {
        padding-bottom: 1rem;
    }

    .xs\:pt-4,
    .xs\:py-4 {
        padding-top: 1rem;
    }

    .xs\:pb-4 {
        padding-bottom: 1rem;
    }

    .xs\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }

    .xs\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (min-width:640px) {
    .sm\:top-0 {
        top: 0;
    }

    .sm\:top-auto {
        top: auto;
    }

    .sm\:right-8 {
        right: 2rem;
    }

    .sm\:bottom-8 {
        bottom: 2rem;
    }

    .sm\:bottom-20 {
        bottom: 5rem;
    }

    .sm\:mx-8 {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .sm\:-mx-6 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }

    .sm\:mt-0 {
        margin-top: 0;
    }

    .sm\:mt-4 {
        margin-top: 1rem;
    }

    .sm\:mt-8 {
        margin-top: 2rem;
    }

    .sm\:mr-0 {
        margin-right: 0;
    }

    .sm\:mr-2 {
        margin-right: .5rem;
    }

    .sm\:mr-4 {
        margin-right: 1rem;
    }

    .sm\:mb-4 {
        margin-bottom: 1rem;
    }

    .sm\:ml-2 {
        margin-left: .5rem;
    }

    .sm\:block {
        display: block;
    }

    .sm\:inline-block {
        display: inline-block;
    }

    .sm\:hidden {
        display: none;
    }

    .sm\:h-24 {
        height: 6rem;
    }

    .sm\:h-96 {
        height: 24rem;
    }

    .sm\:w-40 {
        width: 10rem;
    }

    .sm\:w-auto {
        width: auto;
    }

    .sm\:w-1\/2 {
        width: 50%;
    }

    .sm\:w-5\/12 {
        width: 41.666667%;
    }

    .sm\:w-7\/12 {
        width: 58.333333%;
    }

    .sm\:max-w-md {
        max-width: 28rem;
    }

    .sm\:flex-row {
        flex-direction: row;
    }

    .sm\:flex-col {
        flex-direction: column;
    }

    .sm\:items-start {
        align-items: flex-start;
    }

    .sm\:items-center {
        align-items: center;
    }

    .sm\:justify-between {
        justify-content: space-between;
    }

    .sm\:overflow-hidden {
        overflow: hidden;
    }

    .sm\:rounded-lg {
        border-radius: .5rem;
    }

    .sm\:rounded-2xl {
        border-radius: 1rem;
    }

    .sm\:border {
        border-width: 1px;
    }

    .sm\:border-r {
        border-right-width: 1px;
    }

    .sm\:border-b-0 {
        border-bottom-width: 0;
    }

    .sm\:p-4 {
        padding: 1rem;
    }

    .sm\:p-8 {
        padding: 2rem;
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sm\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .sm\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .sm\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .sm\:pb-4 {
        padding-bottom: 1rem;
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }

    .sm\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}

@media (min-width:768px) {
    .md\:relative {
        position: relative;
    }

    .md\:mt-0 {
        margin-top: 0;
    }

    .md\:mt-4 {
        margin-top: 1rem;
    }

    .md\:mr-1 {
        margin-right: .25rem;
    }

    .md\:ml-1 {
        margin-left: .25rem;
    }

    .md\:block {
        display: block;
    }

    .md\:hidden {
        display: none;
    }

    .md\:w-48 {
        width: 12rem;
    }

    .md\:w-1\/2 {
        width: 50%;
    }

    .md\:w-1\/3 {
        width: 33.333333%;
    }

    .md\:max-w-sm {
        max-width: 24rem;
    }

    .md\:max-w-md {
        max-width: 28rem;
    }

    .md\:flex-row {
        flex-direction: row;
    }

    .md\:flex-col {
        flex-direction: column;
    }

    .md\:items-center {
        align-items: center;
    }

    .md\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px*var(--tw-space-x-reverse));
        margin-left: calc(0px*(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-x-2>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem*var(--tw-space-x-reverse));
        margin-left: calc(0.5rem*(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-x-3>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.75rem*var(--tw-space-x-reverse));
        margin-left: calc(0.75rem*(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-x-4>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem*var(--tw-space-x-reverse));
        margin-left: calc(1rem*(1 - var(--tw-space-x-reverse)));
    }

    .md\:space-y-0>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px*(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px*var(--tw-space-y-reverse));
    }

    .md\:space-y-4>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1rem*(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1rem*var(--tw-space-y-reverse));
    }

    .md\:rounded-2xl {
        border-radius: 1rem;
    }

    .md\:rounded-t-2xl {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .md\:pl-0 {
        padding-left: 0;
    }

    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (min-width:1024px) {
    .lg\:absolute {
        position: absolute;
    }

    .lg\:left-50-256 {
        left: calc(50% - 256px);
    }

    .lg\:-mx-8 {
        margin-left: -2rem;
        margin-right: -2rem;
    }

    .lg\:mt-0 {
        margin-top: 0;
    }

    .lg\:mt-1 {
        margin-top: .25rem;
    }

    .lg\:ml-4 {
        margin-left: 1rem;
    }

    .lg\:block {
        display: block;
    }

    .lg\:table-row {
        display: table-row;
    }

    .lg\:hidden {
        display: none;
    }

    .lg\:w-4\/12 {
        width: 33.333333%;
    }

    .lg\:min-w-75 {
        min-width: 300px;
    }

    .lg\:rounded-2xl {
        border-radius: 1rem;
    }

    .lg\:p-0 {
        padding: 0;
    }

    .lg\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .lg\:py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .lg\:py-3 {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }

    .lg\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .lg\:pr-2 {
        padding-right: .5rem;
    }

    .lg\:pr-4 {
        padding-right: 1rem;
    }

    .lg\:pl-2 {
        padding-left: .5rem;
    }

    .lg\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }
}

@media (min-width:1280px) {
    .xl\:static {
        position: static;
    }

    .xl\:mt-0 {
        margin-top: 0;
    }

    .xl\:flex {
        display: flex;
    }

    .xl\:w-1\/2 {
        width: 50%;
    }

    .xl\:w-4\/12 {
        width: 33.333333%;
    }

    .xl\:max-w-none {
        max-width: none;
    }

    .xl\:flex-row {
        flex-direction: row;
    }

    .xl\:space-x-4>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem*var(--tw-space-x-reverse));
        margin-left: calc(1rem*(1 - var(--tw-space-x-reverse)));
    }

    .xl\:space-y-0>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px*(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px*var(--tw-space-y-reverse));
    }

    .xl\:space-y-4>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1rem*(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1rem*var(--tw-space-y-reverse));
    }

    .xl\:border-r {
        border-right-width: 1px;
    }

    .xl\:pt-4 {
        padding-top: 1rem;
    }
}

@media (min-width:1536px) {
    .\32xl\:h-24 {
        height: 6rem;
    }
}